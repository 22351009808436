<template>
  <sign-block
    v-if="display"
    title-text="专业技术职务聘任证书"
    :request="request"
    :column-list="columnList"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :form-parms-add="formParmsAdd"
    table-size="large"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    @dataFileUpdate="dataFileUpdate"
    :dataFilefuns="dataFilefuns"
    :dealData="dealData"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modalXp" width="1000px" v-if="modalXp">
      <fm-title title-text="续聘记录" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'add', label: '新增'}]"></fm-title>
      <fm-table-new
        @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'log')"
        :toolbox="showToolBox"
        export-file-name="专业技术职务聘任证书续聘记录"
        :simple-filter="true"
        :columns="columnsXp"
        border="row"
        :data-list="choose ? choose.updateLogList : []"
        :stripe="false"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActionsXp" :row="row"></table-actions>
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="新增续聘记录"
      :open-dialog.sync="openDialogXp"
      :form-parms="formParmsAdd.filter(v => ['hireWorker', 'hireStartDate', 'hireEndDate'].includes(v.key))"
      :old-data="chooseXp"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialogXp = false">
    </fm-form-dialog>
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'

import TableActions from '@/components/base/TableActions'
import {
  workerCertificateRequest as request
} from '../../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {TableActions},
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    dataFilefuns () {
      return {
        get: this.$authFunProxy['hrms.worker.worker_certificate_pro_hire-getDataFile'],
        upload: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_pro_hire-updateDataFile'],
        down: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_pro_hire-downDataFile'],
        del: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_pro_hire-delDataFile'],
        mkdir: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_pro_hire-mkdirDataFile']
      }
    },
    tableActionsXp () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    baseColumnsXp () {
      return [{
        title: '聘任职务',
        field: 'hireWorker'
      },
      {
        title: '聘期起始日期',
        field: 'hireStartDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
        }
      },
      {
        title: '聘期终止日期',
        field: 'hireEndDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        slot: 'actions',
        search: false,
        export: false,
        configurable: false
      }]
    },
    columnsXp () {
      return this.$getTableConfig('log', this.baseColumnsXp)
    },
    tableActions () {
      // let vm = this
      let data = [{
      //   key: 'xp',
      //   label: '续聘',
      //   dealFun: (pageVm, parm2) => {
      //     vm.pageVm = pageVm
      //     vm.choose = Object.assign({}, parm2)
      //     vm.modalXp = true
      //   }
      // },
      // {
        key: 'data_file', label: '扫描件'},
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return this.readOnly ? [data[0]] : data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.certificateTypeList,
          label: '证件类型',
          key: 'type',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '专业',
          key: 'hirePro'
        },
        {
          type: 'input',
          label: '聘任单位',
          key: 'hireOrg'
        },
        {
          type: 'input',
          label: '聘任职务',
          key: 'hireWorker'
        },
        {
          type: 'input',
          label: '岗位等级',
          key: 'mType'
        },
        {
          type: 'datePicker',
          label: '聘期起始日期',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '编号',
          field: 'id',
          sort: true,
          fixed: 'left',
          width: 100
        },
        {
          title: '证书编码',
          field: 'code',
          fixed: 'left',
          sort: true
        },
        {
          title: '类型',
          field: 'typeText',
          fixed: 'left',
          sort: true
        },
        {
          title: '专业',
          field: 'hirePro',
          sort: true
        },
        {
          title: '聘任单位',
          field: 'hireOrg',
          sort: true
        },
        {
          title: '岗位等级',
          field: 'mType',
          sort: true
        },
        {
          title: '聘任职务',
          field: 'hireWorker',
          sort: true
        },
        {
          title: '聘期起始日期',
          field: 'hireStartDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          }
        },
        {
          title: '聘期终止日期',
          field: 'hireEndDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
          }
        },
        {
          title: '证件状态',
          field: 'status',
          sort: true
        },
        {
          title: '扫描件状态',
          field: 'imgStatusText',
          sort: true
        },
        {
          title: '最近更新扫描件时间',
          field: 'imgUploadTime',
          width: 180,
          sort: true,
          dataType: Date
        }]
        return data
      }
    }
  },
  created () {
    if (!this.display) {
      this.loadData()
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      if (!data || !data.id) {
        newData.type = data.type
      }
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_certificate_pro_hire'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_certificate_pro_hire'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.hireStartDate) <= this.historyDate)
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该续聘记录吗?'})
      if (result) {
        this.choose.updateLogList = this.choose.updateLogList.filter((v) => this.chooseXp.lId !== v.lId)
        this.choose.updateLogList.forEach((v2, index2) => v2.lId = index2)
        await request.update(this.choose.id, {
          updateLog: JSON.stringify(this.choose.updateLogList)
        })
        await this.pageVm.loadData()
        this.choose.updateLogList = this.choose.updateLogList.map(v => v)
      }
    },
    async formSubmit (data, resolve) {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.lId) {
        let i = 0
        while (i < this.choose.updateLogList.length) {
          if (i + 1 === data.lId) {
            this.choose.updateLogList[i] = Object.assign({}, this.choose.updateLogList[i], data)
          }
          i += 1
        }
        await request.update(this.choose.id, {
          updateLog: JSON.stringify(this.choose.updateLogList)
        })
        await this.pageVm.loadData()
        this.choose.updateLogList = this.choose.updateLogList.map(v => v)
        this.openDialogXp = false
      } else {
        for (let key in data) {
          if (data[key] instanceof Date) {
            data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
          }
        }
        this.choose.updateLogList.push(Object.assign({
          'lId': this.choose.updateLogList.length + 1
        }, this.choose, {updateLogList: undefined}))
        data.updateLog = JSON.stringify(this.choose.updateLogList)
        request.update(this.choose.id, data)
        this.pageVm.loadData()
      }
      resolve()
      this.openDialogXp = false
    },
    tableAction (parm) {
      this.chooseXp = parm.data
      if (parm.action === 'edit') {
        this.openDialogXp = true
      } else if (parm.action === 'del') {
        this.delData()
      }
    },
    initAddFormData () {
      return {
        type: 'pro_hire',
        workerId: this.workerId
      }
    },
    clickTitleMenu () {
      this.chooseXp = null
      this.openDialogXp = true
    },
    async loadData () {
      let parm = {
        type: 'pro_hire',
        workerId: this.workerId
      }
      let data = await request.get(parm)
      this.$emit('dataOk', data && data.length > 0)
      data.forEach(v => {
        v.updateLogList = v.updateLog ? JSON.parse(v.updateLog) : []
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.filter(v => !['type'].includes(v.key)).map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }

      return data
    }
  },
  data () {
    return {
      choose: null,
      loading: false,
      modalXp: false,
      src: null,
      chooseXp: null,
      openDialogXp: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
