<template>
  <sign-block
    v-if="display"
    title-text="职称聘任记录"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    table-size="large"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    :tableActions="tableActions"
    :dealFormData="dealFormData"
    :form-parms-update="formParmsAdd"
    :dealData="dealData"
    :initAddFormData="initAddFormData"
    :initSearchData="{workerId: this.workerId}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  workerJobTitleRequest as request
} from '../../../../api'

import {
  applyFormSubmit,
  applyDel
} from './apply'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_job_title'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_job_title'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.hireStartDate) <= this.historyDate)
    },
    dealFormData (data) {
      // if (data.startDate && data.endDate && this.timeBj(data.startDate, data.endDate)) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '离任时间需要大于上岗时间。'
      //   })
      //   throw Error()
      // }
      if (data.hireStartDate && data.hireEndDate && this.timeBj(data.hireStartDate, data.hireEndDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '聘任终止时间需要大于聘任起始时间。'
        })
        throw Error()
      }
      return data
    },
    initAddFormData () {
      return {
        workerId: this.workerId
      }
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    async loadData () {
      let data = await request.get({
        workerId: this.workerId
      })
      data.sort((a, b) => a.workerCode - b.workerCode)
      data.forEach((v) => {
        let startY = v.hireStartDate ? Number(v.hireStartDate.slice(0, 4)) : null
        let endY = v.hireEndDate ? Number(v.hireEndDate.slice(0, 4)) : new Date().getFullYear()
        v.years = startY ? endY - startY + 1 : null
      })

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }

      this.$emit('dataOk', data && data.length > 0)
      return data
    }
  },
  created () {
    if (!this.readOnly) {
      this.$store.dispatch('loadJobTitleList')
    }
    if (!this.display) {
      this.loadData()
    }
  },
  computed: {
    tableActions () {
      return this.readOnly ? [] : [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: [{key: '医师', label: '医师'}, {key: '护理', label: '护理'}],
          label: '岗位专业',
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.jobTitleList,
          label: '岗位',
          key: 'jobTitleId',
          check: {
            required: true
          }
        },
        // {
        //   type: 'datePicker',
        //   label: '上岗时间',
        //   key: 'startDate',
        //   check: {
        //     required: true
        //   }
        // },
        // {
        //   type: 'datePicker',
        //   label: '离任时间',
        //   key: 'endDate'
        // },
        {
          type: 'datePicker',
          label: '聘任起始时间',
          key: 'hireStartDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '聘任终止时间',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '在岗单位',
          key: 'hisName'
        }]
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工编号',
        sort: true,
        fixed: 'left',
        field: 'workerCode'
      },
      {
        title: '职工',
        fixed: 'left',
        sort: true,
        field: 'workerName'
      },
      {
        title: '岗位专业',
        fixed: 'left',
        sort: true,
        field: 'type'
      },
      {
        title: '岗位类型',
        fixed: 'left',
        sort: true,
        field: 'jobTitleType'
      },
      {
        title: '岗位层级',
        sort: true,
        field: 'topLevel'
      },
      {
        title: '职称级别',
        sort: true,
        field: 'mLevel'
      },
      {
        title: '岗位等级',
        sort: true,
        field: 'jobTitleTitle'
      },
      // {
      //   title: '在岗时间段',
      //   sort: true,
      //   field: 'startDate',
      //   width: 200,
      //   render: (h, rowData) => {
      //     let text = ''
      //     if (rowData.startDate) {
      //       text += rowData.startDate ? rowData.startDate.slice(0, 10) : ''
      //     }
      //     text += rowData.endDate ? ('至' + rowData.endDate.slice(0, 10)) : '至今'
      //     return h('div', text)
      //   }
      // },
      {
        title: '聘任起始时间',
        sort: true,
        field: 'hireStartDate',
        width: 130,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
        }
      },
      {
        title: '聘任终止时间',
        sort: true,
        field: 'hireEndDate',
        width: 130,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今')
        }
      },
      {
        title: '在岗单位',
        sort: true,
        field: 'hisName'
      },
      {
        title: '累计聘任上岗年限',
        sort: true,
        field: 'years',
        dataType: Number
      }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
