<style lang="less" scoped>
  .worker-all-info {
    & > div {
      // background-color: rgba(232, 232, 232, .2);
      padding: 0 16px;
      & + div {
        margin-top: 27px;
      }
    }
  }  
</style>

<template>
  <div class="content worker-all-info">
    <worker-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></worker-data>
    <job-title-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></job-title-data>
    <his-work-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></his-work-data>
    <edu-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></edu-data>
    <agreement-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></agreement-data>
    <certificate-data :worker-id="workerId" :history-date="historyDate" :readOnly="true"></certificate-data>
    <certificate-data-doctor-pra :worker-id="workerId" :history-date="historyDate" :readOnly="true"></certificate-data-doctor-pra>
    <certificate-data-pro-hire :worker-id="workerId" :history-date="historyDate" :readOnly="true"></certificate-data-pro-hire>
    <certificate-data-doctor-qua :worker-id="workerId" :history-date="historyDate" :readOnly="true"></certificate-data-doctor-qua>
  </div>
</template>

<script>
import WorkerData from './allInfo/workerData'
import HisWorkData from './allInfo/hisWorkData'
import EduData from './allInfo/eduData'
import JobTitleData from './allInfo/jobTitleData'
import AgreementData from './allInfo/agreementData'
import CertificateData from './allInfo/certificateData'
import CertificateDataDoctorPra from './allInfo/certificateDataDoctorPra'
import CertificateDataProHire from './allInfo/certificateDataProHire'
import CertificateDataDoctorQua from './allInfo/certificateDataDoctorQua'
export default {
  components: {
    WorkerData,
    HisWorkData,
    EduData,
    JobTitleData,
    AgreementData,
    CertificateData,
    CertificateDataDoctorPra,
    CertificateDataProHire,
    CertificateDataDoctorQua
  },
  props: {
    workerId: {
      type: [String, Number]
    },
    historyDate: {
      type: Date,
      default: null
    }
  }
}
</script>

<style lang="less">
.worker-all-info {
  .content-body {
    padding-left: 16px;
  }
}
</style>
