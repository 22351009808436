import {
  dateOperating
} from '@/fmlib'

export const getLastMonth = (date) => {
  date = dateOperating.computeDay({days: 0, date: (date || new Date())})
  return dateOperating.computeDay({days: (0 - date.getDate() - 1), date: date, format: 'yy-mm-dd'}).slice(0, 7)
}

export const sortData = ({datas, funSort}) => {
  if (datas.length < 2) {
    return datas
  }
  for (let indexA in datas) {
    for (let indexB in datas) {
      let nIndexB = Number(indexB)
      if (nIndexB < (datas.length - 1 - indexA) && funSort(datas[nIndexB], datas[nIndexB + 1])) {
        [datas[nIndexB], datas[nIndexB + 1]] = [datas[nIndexB + 1], datas[nIndexB]]
      }
    }
  }
  return datas
}

export const getBirthByIdNo = (idNo) => {
  let birth = null
  if (typeof idNo === 'string' && idNo.length > 14) {
    let year = idNo.slice(6, 10)
    let month = idNo.slice(10, 12)
    let day = idNo.slice(12, 14)
    birth = year + '-' + month + '-' + day
    if (isNaN(new Date(birth).getTime())) {
      birth = null
    }
    birth = birth + ' 00:00:00'
  }
  return birth
}

export const getAge = (birth, now = new Date()) => {
  let age = null
  if (birth) {
    let d1 = dateOperating.computeDay({days: 0, date: birth})
    age = now.getFullYear() - d1.getFullYear()
    if (now.getMonth() < d1.getMonth() || (now.getMonth() === d1.getMonth() && now.getDate() < d1.getDate())) {
      age = age - 1
    }
  }
  return age
}
