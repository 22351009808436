<template>
  <sign-block
    v-if="display"
    title-text="执业证书"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :pageFormSubmit="pageFormSubmit"
    :pageDelData="pageDelData"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :dataFilefuns="dataFilefuns"
    table-size="large"
    :tableActions="tableActions"
    :form-parms-update="formParmsUpdate"
    :initAddFormData="initAddFormData"
    :dealFormData="dealFormData"
    :dealData="dealData"
    :tableActionsFixed="true"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modalBg" width="1000px" v-if="modalBg">
      <fm-title title-text="变更记录">
      </fm-title>
      <div class="zyzs-bjjl">
        <div v-for="item in choose.updateLogList" :key="item.id">
          <div class="jl-item" v-if="item.type === 'change'">
            <fm-title title-text="变更注册"></fm-title>
            <div class="log-info">
              <div>变更日期:</div>
              <span>{{item.changeDate}}</span>
            </div>
            <div class="log-info">
              <div>变更事项:</div>
              <span>{{item.changeContent}}</span>
            </div>
          </div>
          <div class="jl-item" v-if="item.type === 'goon'">
            <fm-title title-text="延续注册"></fm-title>
            <div class="log-info">
              <div>注册日期:</div>
              <span>{{item.registDate}}</span>
            </div>
            <div class="log-info">
              <div>注册有效期至:</div>
              <span>{{item.registEndDate}}</span>
            </div>
          </div>
          <div class="jl-item" v-if="item.type === 'first'">
            <fm-title title-text="首次注册"></fm-title>
            <div class="log-info">
              <div>注册日期:</div>
              <span>{{item.registDate}}</span>
            </div>
            <div class="log-info">
              <div>注册有效期至:</div>
              <span>{{item.registEndDate}}</span>
            </div>
          </div>
        </div>
      </div>
    </fm-modal>
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'

import {
  workerCertificateRequest as request
} from '../../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  created () {
    if (!this.display) {
      this.loadData()
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      if (!data || !data.id) {
        newData.type = data.type
      }

      delete hisData.updateLogList
      delete hisData.changeTime
      delete hisData.passOrg

      delete newData.updateLogList
      delete newData.changeTime
      delete newData.passOrg

      return Object.keys(newData).length ? {hisData, newData} : false
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_certificate_doctor_pra'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_certificate_doctor_pra'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.getDate) <= this.historyDate)
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    async loadData () {
      let parm = {
        type: 'doctor_pra',
        workerId: this.workerId
      }
      let data = await request.get(parm)
      this.$emit('dataOk', data && data.length > 0)
      data.forEach(v => {
        v.updateLogList = v.updateLog ? JSON.parse(v.updateLog) : []
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.filter(v => !['type'].includes(v.key)).map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }
      
      return data 
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'doctor_pra'
      }
    },
    dealFormData (data, pageVm) {
      if (pageVm.chooseData && pageVm.chooseData.id) {
        let changMap = {}
        Object.keys(pageVm.chooseData).filter(v => v !== 'updateLogList').forEach(key => {
          let newData = data[key]
          if (newData instanceof Date) {
            newData = dateOperating.computeDay({days: 0, date: newData, format: 'yy-mm-dd hh:mm:ss'})
          }
          if (pageVm.chooseData[key] != newData) {
            changMap[key] = {
              his: pageVm.chooseData[key],
              new: newData
            }
          }
        })
        let cKeys = Object.keys(changMap)
        if (cKeys.length > 0) {
          let changContent = ''
          cKeys.forEach((key) => {
            let c = this.columnList.find(v => v.field === key)
            if (c) {
              changContent += c.title + '由“' + (changMap[key].his || '空') + '”变更为“' + (changMap[key].new || '空') + '”；'
            }
          })
          data.updateLogList.push({
            changContent: changContent,
            passOrg: data.passOrg,
            changeTime: dateOperating.computeDay({days: 0, date: data.changeTime, format: 'yy-mm-dd'})
          })
          data.updateLog = JSON.stringify(data.updateLogList)
        }
      }
      return data
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    dataFilefuns () {
      return {
        get: this.$authFunProxy['hrms.worker.worker_certificate_doctor_pra-getDataFile'],
        upload: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_doctor_pra-updateDataFile'],
        down: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_doctor_pra-downDataFile'],
        del: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_doctor_pra-delDataFile'],
        mkdir: !this.readOnly && this.$authFunProxy['hrms.worker.worker_certificate_doctor_pra-mkdirDataFile']
      }
    },
    baseColumnsBg () {
      return [{
        title: '变更项目',
        field: 'changContent'
      },
      {
        title: '变更日期',
        field: 'changeTime'
      },
      {
        title: '批准机关',
        field: 'passOrg'
      }]
    },
    columnsBg () {
      return this.$getTableConfig('log', this.baseColumnsBg)
    },
    tableActions () {
      let vm = this
      let data = [{
        key: 'changelog',
        label: '变更记录',
        dealFun: (pageVm, parm2) => {
          vm.pageVm = pageVm
          vm.choose = Object.assign({}, parm2)
          vm.modalBg = true
        }
      },
      {key: 'data_file', label: '扫描件'},
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return this.readOnly ? data.slice(0, 2) : data
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          if (item.key === 'workerId') {
            newItem.fmDisabled = true
          }
          data.push(newItem)
        })
        data.push({
          type: 'datePicker',
          label: '变更时间',
          key: 'changeTime',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '批准机关',
          key: 'passOrg',
          check: {
            required: true
          }
        })
        return data
      }
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.certificateTypeList,
          label: '证件类型',
          key: 'type',
          fmDisabled: true
        },
        {
          type: 'select',
          selectDatas: [{key: '医师执业证书', label: '医师执业证书'}, {key: '护士执业证书', label: '护士执业证书'}],
          label: '执业证书类型',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'getDate',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '执业地点',
          key: 'practiceAddress'
        },
        {
          type: 'input',
          label: '执业类别',
          key: 'practiceType'
        },
        {
          type: 'input',
          label: '执业范围',
          key: 'practiceRange'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    columnList () {
      let data = [{
          title: '执业证书类型',
          field: 'mType',
          sort: true
        },
        {
          title: '编号',
          field: 'id',
          fixed: 'left',
          sort: true,
          width: 100
        },
        {
          title: '证书编码',
          field: 'code',
          fixed: 'left',
          sort: true
        },
        {
          title: '类型',
          fixed: 'left',
          field: 'typeText',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '签发人',
          field: 'issueUser',
          sort: true,
          width: 100
        },
        {
          title: '发证日期',
          field: 'getDate',
          width: 130,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          }
        },
        {
          title: '执业地点',
          field: 'practiceAddress',
          sort: true
        },
        {
          title: '执业类别',
          field: 'practiceType',
          sort: true
        },
        {
          title: '执业范围',
          field: 'practiceRange',
          sort: true
        },
        {
          title: '证件状态',
          field: 'status',
          sort: true
        },
        {
          title: '扫描件状态',
          field: 'imgStatusText',
          sort: true
        },
        {
          title: '最近更新扫描件时间',
          field: 'imgUploadTime',
          width: 180,
          sort: true,
          dataType: Date
        }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      choose: null,
      modalBg: false
    }
  }
}
</script>

<style lang="less" scoped>
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>